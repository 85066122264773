export const environment = {
	/*production: false,
	HOST: 'https://gestiondiag.oa.r.appspot.com',
  logo_files:'https://storage.googleapis.com/logo_gestiondiag/',
  stripe_public_key: "pk_test_FXSpRsGJ8EQqLcu0YuSy1uV4",*/
  production: false,
  HOST: 'https://dev-dot-gestiondiag.oa.r.appspot.com',
  logo_files:'https://storage.googleapis.com/logo_gestiondiag',
  stripe_public_key: "pk_test_FXSpRsGJ8EQqLcu0YuSy1uV4",
  FILE_UPLOADS: "https://dev.gestion-diagnostic.fr",
  appVersion: 3.0,
  HOST_adresses: 'https://adresses.gestion-diagnostic.fr'
};
//version gcloud
